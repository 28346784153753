import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Autocomplete,
  Box,
  Button,
  capitalize,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import passwordGenerator from "generate-password-browser";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Company,
  IOrganizationUnit,
  Resource,
  Role,
  Scope,
} from "../../../../../types/NendaTypes";
import { getUserRole } from "../../../../redux/auth.redux";
import { getCompanies } from "../../store/reducers/companyReducer";
import { getPremises } from "../../store/reducers/organizationUnitReducer";
import DropdDownPaper from "../../ui-components/DropdownPaper";
import InputSelect from "../../ui-components/input/InputSelect";
import TextInput from "../../ui-components/input/TextInput";
import PermissionsGate from "../PermissionGate";
import { t } from "i18next";

const style = {
  textField: {
    maxWidth: "50ch",
  },
};

export const isPremiseSelectVisible = (role) => {
  if ([Role.PremiseUser, Role.PremiseAdmin].includes(role)) {
    return true;
  }
  return false;
};
type CreateEditUserProps = {
  onUserNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPasswordChange: (value: string) => void;
  onSendEmailChange: () => void;
  onCompanyChange: (e: SelectChangeEvent) => void;
  onRoleChange: (value: Role) => void;
  onPremisesChange: (value: string[]) => void;
  userName: string;
  password: string;
  shouldSendEmail: boolean;
  company: string;
  role: Role;
  selectedPremiseIds: string[];
  isEdit?: boolean;
};
const notCheckedIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const CreateEditUserForm = ({
  onUserNameChange,
  onPasswordChange,
  onSendEmailChange,
  onCompanyChange,
  onPremisesChange,
  onRoleChange,
  userName,
  password,
  shouldSendEmail,
  company,
  role,
  selectedPremiseIds,
  isEdit,
}: CreateEditUserProps) => {
  const userRole = useSelector(getUserRole);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsPasswordVisible(!isPasswordVisible);
  };

  const companies = useSelector(getCompanies);
  const premises = useSelector(getPremises);

  const companyPremises = (companyId: string) => {
    return premises.filter(
      (p) =>
        p.company &&
        (typeof p.company === "string" ? p.company : p.company._id) ===
          companyId
    );
  };

  const generatePassword = () => {
    const pwd = passwordGenerator.generate({ excludeSimilarCharacters: true });
    onPasswordChange(pwd);
  };

  const handlePremiseSelect = (_event, value: IOrganizationUnit[]) => {
    onPremisesChange(value.map((premise) => premise._id));
  };

  const onRoleSelect = (e: SelectChangeEvent) => {
    onRoleChange(e.target.value as Role);
  };
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onPasswordChange(e.target.value);
  };

  let selectableRoles = Object.values(Role);

  //Only admin can create admin users
  if (userRole !== Role.Admin) {
    selectableRoles = selectableRoles.filter((role) => role !== Role.Admin);
  }

  const selectedPremises = companyPremises(company).filter((premise) =>
    selectedPremiseIds.includes(premise._id)
  );

  return (
    <Box>
      <Box mt={3}>
        <Stack spacing={2}>
          <PermissionsGate
            restriction={{
              resource: Resource.User,
              scopes: [Scope.CanAdministrate],
            }}
          >
            <React.Fragment>
              <InputSelect
                id="role-select"
                value={role || ""}
                title={t("customerportal.pages.users.role")}
                variant="standard"
                onChange={onRoleSelect}
              >
                {selectableRoles.map((role, index) => (
                  <MenuItem key={index} value={role}>
                    {capitalize(role)}
                  </MenuItem>
                ))}
              </InputSelect>
              <InputSelect
                id="company-select"
                value={company || ""}
                title={t("customerportal.pages.users.company")}
                variant="standard"
                onChange={onCompanyChange}
              >
                {companies.map((com: Company) => (
                  <MenuItem key={com._id} value={com._id}>
                    {com.name}
                  </MenuItem>
                ))}
              </InputSelect>
              {isPremiseSelectVisible(role) && (
                <Box>
                  <Typography
                    sx={{
                      paddingLeft: "0.3rem",
                      fontSize: "0.7rem",
                    }}
                  >
                    {t("customerportal.pages.users.premises")}
                  </Typography>
                  <Autocomplete
                    multiple
                    autoComplete={false}
                    id="premise-select"
                    options={companyPremises(company)}
                    value={selectedPremises}
                    onChange={handlePremiseSelect}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    PaperComponent={DropdDownPaper}
                    renderOption={(props, option, { selected }) => {
                      return (
                        <li {...props}>
                          <Checkbox
                            icon={notCheckedIcon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      );
                    }}
                    sx={style.textField}
                    renderInput={(params) => (
                      <TextInput variant="standard" {...params} />
                    )}
                  />
                </Box>
              )}
            </React.Fragment>
          </PermissionsGate>
          <TextInput
            disabled={isEdit}
            sx={style.textField}
            id="username"
            variant="standard"
            label="Email"
            value={userName}
            autoComplete="off"
            onChange={onUserNameChange}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextInput
              sx={style.textField}
              variant="standard"
              id="outlined-adornment-password"
              type={isPasswordVisible ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePassword}
                      onMouseDown={togglePassword}
                      edge="end"
                    >
                      {isPasswordVisible ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label={t("customerportal.pages.users.password")}
            />
            <Button
              sx={{
                p: 1,
                pt: 0,
                pb: 0,
                width: "fit-content",
                justifyContent: "flex-start",
              }}
              variant="text"
              onClick={generatePassword}
            >
              {t("customerportal.pages.users.generate_password")}
            </Button>
            <FormControlLabel
              sx={{ width: "fit-content", justifyContent: "flex-start" }}
              control={
                <Checkbox
                  name="sendEmailCheckbox"
                  checked={shouldSendEmail}
                  onChange={onSendEmailChange}
                />
              }
              label={t("customerportal.pages.users.send_email")}
            />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
